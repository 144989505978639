/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Form, FormGroup, Input, Label, Spinner } from 'reactstrap'
import reportApi from '_api/report'
import userApi from '_api/user'
import { getUserInfo, setUserInfo } from '_utils/localData'

const InfoItem = ({ data, name, placeholder, error, innerRef, type = 'text' }) => {
  const [value, setValue] = useState('')

  const onChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (data && name) setValue(data[name])
  }, [data, name])

  return (
    <div className='form-group'>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className='form-control'
        type={type}
        ref={innerRef}
      />
      <small style={{ color: '#ff9900' }}>{error}</small>
    </div>
  )
}

const UpdateProfile = () => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)

  const [currentUser, setCurrentUser] = useState(null)
  const [newCurrentUser, setNewCurrentUser] = useState(null)
  const [error, setError] = useState({ firstName: '', lastName: '', phone: '', postalCode: '', uen: '' })
  const [isPayWithUEN, setIsPayWithUEN] = useState()

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const phoneRef = useRef(null)
  const uenRef = useRef(null)
  const postalCodeRef = useRef(null)

  const validate = () => {
    const fname = firstNameRef.current.value
    const lname = lastNameRef.current.value
    const phone = phoneRef.current.value
    // const uen = uenRef.current.value

    if (!fname) {
      setError({ ...error, firstName: 'First Name cannot be blank' })
      return false
    }
    if (fname) {
      const splitFName = fname.split(' ')
      if (splitFName.length > 1 && splitFName[1]) {
        setError({ ...error, firstName: 'First Name only one word' })
        return false
      }
    }
    if (!lname) {
      setError({ ...error, lastName: 'Last Name cannot be blank' })
      return false
    }
    if (!phone) {
      setError({ ...error, phone: 'Phone cannot be blank' })
      return false
    }

    return true
  }

  const handleCheckboxChange = async () => {
    if (!currentUser.uen) {
       addToast('Please fill your uen', { appearance: 'error', autoDismiss: true })
      return
    }
    try {
      await userApi.updatePayWithUen({ isPayWithUEN: !isPayWithUEN })
      const userInfo = getUserInfo()
      setUserInfo({ ...userInfo, isPayWithUEN: !isPayWithUEN })
      setIsPayWithUEN((prev) => !prev)
      addToast('Update success', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast('Something went wrong', { appearance: 'error', autoDismiss: true })
    }
  };

  const handleSave = async () => {
    const fname = firstNameRef.current.value
    const lname = lastNameRef.current.value
    const name = `${fname.trim()} ${lname.trim()}`
    const phone = phoneRef.current.value
    const uen = uenRef.current.value
    const postalCode = postalCodeRef.current.value
    const newProfile = { name, phone, postalCode, uen }
    if (validate()) {
      setIsLoading(true)
      userApi
        .updateUser(newProfile)
        .then(({ msgCode }) => {
          if (msgCode % 100 === 0) {
            setError({ firstName: '', lastName: '', phone: '', postalCode: '', uen: '' })
            addToast('Saved Successfully', { appearance: 'success', autoDismiss: true })
            userApi
              .getUser()
              .then(({ msgResp }) => {
                setIsPayWithUEN(msgResp.isPayWithUEN || false)
                setCurrentUser(msgResp)
                setUserInfo(msgResp)
              })
              .catch(({ msgResp, msgCode }) => {
                reportApi.report({ message: `[Update profile 01]${msgCode} - ${msgResp}` })
                addToast(msgResp, { appearance: 'error', autoDismiss: true })
              })
          }
          setIsLoading(false)
        })
        .catch(({ msgCode, msgResp }) => {
          reportApi.report({ message: `[Update profile 02]${msgCode} - ${msgResp}` })
          const objError = {}
          if (msgCode % 100 === 99) {
            addToast('Something went wrong', { appearance: 'error', autoDismiss: true })
          } else addToast(msgResp, { appearance: 'error', autoDismiss: true })
          setError(objError)
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    setCurrentUser(getUserInfo())
    setIsPayWithUEN(getUserInfo().isPayWithUEN || false)
  }, [])

  const { name } = currentUser || {}

  useEffect(() => {
    if (name) {
      const firstName = name.split(' ').slice(0, 1)
      const lastName = name.split(' ').slice(1).join(' ')
      const nCurrentUser = { ...currentUser, firstName, lastName }
      setNewCurrentUser(nCurrentUser)
    }
  }, [name])
  return (
    <React.Fragment>
      {!currentUser && <></>}
      {currentUser && (
        <>
          <div className='c-txt-14 text-center'>Profile Update ({currentUser?.email})</div>
          <Form className="mt-2">
            <FormGroup check inline>
            <Input
              type="checkbox"
              checked={isPayWithUEN}
              className="border"
              onChange={handleCheckboxChange}
            />
              <Label check style={{ fontSize: '16px' }}>
                QR Code Payment with UEN
              </Label>
            </FormGroup>
          </Form>

          <InfoItem
            data={newCurrentUser}
            name='firstName'
            innerRef={firstNameRef}
            placeholder='First Name'
            error={error.firstName}
          />
          <InfoItem
            data={newCurrentUser}
            name='lastName'
            innerRef={lastNameRef}
            placeholder='Last Name'
            error={error.lastName}
          />
          <InfoItem
            data={currentUser}
            name='phone'
            innerRef={phoneRef}
            placeholder='Mobile Number'
            error={error.phone}
            type='number'
          />
          <InfoItem
            data={currentUser}
            name='uen'
            innerRef={uenRef}
            placeholder='uen'
            error={error.uen}
          />
          <InfoItem
            data={currentUser}
            name='postalCode'
            innerRef={postalCodeRef}
            placeholder='Postal Code'
            error={error.postalCode}
          />
          <div className='row row--bot align-self-end mt-3'>
            <div className='col-6'>
              <button
                className='btn btn--solid btn--solid03'
                onClick={() => {
                  setCurrentUser(getUserInfo())
                }}
              >
                CANCEL
              </button>
            </div>
            <div className='col-6'>
              <button className='btn btn--main' onClick={handleSave} disabled={isLoading}>
                {isLoading ? <Spinner color='light' size='sm' /> : 'SAVE'}
              </button>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default UpdateProfile
